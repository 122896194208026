import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import Navbar from "../UI/Home/Navbar";
import Footer from "../UI/Home/Footer";

const Blog = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
    <Navbar></Navbar>
      <section className="">
        <section className="pb-16 lg:pb-0">
          <div className="max-w-screen-2xl mx-auto py-8 flex flex-wrap">
            <div class="w-full md:w-full lg:w-4/6 px-4 py-4 mb-8">
              {/* <AwesomeSlider> */}
                <div>
                  {/* <img
                    src="/images/blog1.jpeg"
                    alt="blog"
                    onClick={() => {
                      navigate("/Blog/1");
                    }}
                  /> */}
                  <iframe class="w-full h-96 object-cover mb-8" src="https://www.youtube.com/embed/L_zRhzrgwgE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                {/* <div>
                  <img
                    src="/images/blog2.jpeg"
                    alt="blog"
                    onClick={() => {
                      navigate("/Blog/2");
                    }}
                  />
                </div>
                <div>
                  <img
                    src="/images/blog3.jpeg"
                    alt="blog"
                    onClick={() => {
                      navigate("/Blog/3");
                    }}
                  />
                </div>
                <div>
                  <img
                    src="/images/blog4.jpeg"
                    alt="blog"
                    onClick={() => {
                      navigate("/Blog/4");
                    }}
                  />
                </div>
              </AwesomeSlider> */}
              <div class="flex flex-wrap mx-4 mt-12">
                <div class="w-full md:w-1/2 px-4 mb-8">
                  <div
                    class="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
                    onClick={() => {
                      navigate("/Blog/2");
                    }}
                  >
                    {/* <img
                      src="/images/blog2.jpeg"
                      alt="Blog post"
                      class="w-full h-48 object-cover"
                    /> */}
                    <iframe class="w-full h-48 object-cover" src="https://www.youtube.com/embed/L_zRhzrgwgE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                
                    <div class="p-6">
                      <h3 class="text-xl font-bold mb-2">
                      Tired of dry textbooks and passive learning?
                      </h3>
                      <p class="text-gray-700 text-base">
                      "Be Billionaire" throws open the doors to a dynamic real estate world where you're the boss! This mobile game revolutionizes accounting education by turning theory into an immersive, action-packed business simulation.

                      </p>
                      <a
                        href="#-"
                        class="block mt-4 text-lg font-bold text-gray-900"
                      >
                        Read more &rarr;
                      </a>
                    </div>
                  </div>
                </div>

                <div class="w-full md:w-1/2 px-4 mb-8">
                  <div
                    class="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
                    onClick={() => {
                      navigate("/Blog/3");
                      window.scrollTo(0, 0);
                    }}
                  >
                    {/* <img
                      src="/images/blog3.jpeg"
                      alt="Blog post"
                      class="w-full h-48 object-cover"
                    /> */}
                    <iframe class="w-full h-48 object-cover" src="https://www.youtube.com/embed/T6sH9w_BPp4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                
                    <div class="p-6">
                      <h3 class="text-xl font-bold mb-2">
                      Transforming Accounting Education into a Mobile Game Empire
                      </h3>
                      <p class="text-gray-700 text-base">
                      Forget dusty textbooks and boring lectures! "Be Billionaire" is here to turn accounting into an addictive mobile adventure. Imagine: you're not just crunching numbers, you're building your real estate empire, one strategic deal at a time.

                      </p>
                      <a
                        href="#-"
                        class="block mt-4 text-lg font-bold text-gray-900"
                      >
                        Read more &rarr;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BlogSidebar />
          </div>
        </section>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Blog;
