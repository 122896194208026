import React from "react";
import Footer from "./Home/Footer";
import Navbar from "./Home/Navbar";
import Modal from "./Home/Banner/Modal";
import { toast } from "react-toastify";

const AboutUS = () => {
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  return (
    <>
      <Navbar></Navbar>
      <section className="flex items-center bg-stone-100 xl:h-screen font-poppins dark:bg-gray-800 ">
        <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
          <div className="flex flex-wrap ">
            <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
              <img
                src="img/bb-b.jpg"
                alt=""
                className="relative z-40 object-cover w-full h-96 rounded-3xl"
              />
            </div>
            <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
              <h2 className="mb-4 text-4xl font-semibold text-blue-500 dark:text-gray-300">
                About Us
              </h2>
              <p className="mb-10 text-base leading-7 text-gray-500 dark:text-gray-400">
                Unlock the power of education through the magic of gaming. Our
                platform is a dynamic hub that bridges the world of game
                developers, educational institutions, and students. We believe
                in transforming learning into a thrilling adventure, making
                education engaging, motivating, and fun.Unlock the power of
                education through the magic of gaming. Our platform is a dynamic
                hub that bridges the world of game developers, educational
                institutions, and students. We believe in transforming learning
                into a thrilling adventure, making education engaging,
                motivating, and fun.
              </p>
              <button
                href="#-"
                onClick={() =>
                  document.getElementById("my_modal_2").showModal()
                }
                className="px-4 py-3 text-blue-700 transition-all transform border border-blue-500 rounded-3xl hover:bg-blue-600 dark:border-blue-400 dark:hover:bg-blue-500 dark:hover:text-gray-100 dark:hover:border-blue-500 dark:text-blue-400 hover:text-gray-100"
              >
                Discover more
              </button>

              <Modal></Modal>
            </div>
          </div>
        </div>
      </section>

      <section className="flex items-center bg-stone-100 font-poppins dark:bg-gray-800 ">
        <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
          <div className="justify-center items-center container mx-auto">
            <div className="flex flex-warp justify-center items-center rounded-lg gap-4 mx-auto">
              <div className="w-1/2 justify-center items-center mx-auto">
                <div class="p-3 my-2 shadow-lg flex mx-auto rounded-lg cursor-pointer">
                  <div class="p-2 justify-center items-center">
                    <div className="btn-group btn-group-horizontal lg:btn-group-horizontal md:text-[16px]">
                      <button
                        className={
                          "px-16 py-2 text-[#FF6E04] rounded-md flex items-center space-x-1 mx-auto font-light text-4xl"
                        }
                      >
                        Our Mission
                      </button>
                    </div>
                    <p className="font-light text-center text-gray-500 dark:text-gray-400 sm:text-lg mt-5 text-justify">
                      At Play Games to Learn, we aim to revolutionize education
                      by harnessing the potential of games. Our mission is to
                      provide a collaborative space for game developers and
                      educational institutions to create interactive and
                      stimulating games that enhance learning experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-1/2 justify-center items-center mx-auto">
                <div class="p-3 my-2 shadow-lg flex mx-auto rounded-lg cursor-pointer">
                  <div class="p-2 justify-center items-center">
                    <div className="btn-group btn-group-horizontal lg:btn-group-horizontal md:text-[16px]">
                      <button
                        className={
                          "px-16 py-2 text-[#FF6E04] rounded-md flex items-center space-x-1 mx-auto font-light text-4xl"
                        }
                      >
                        Our Vision
                      </button>
                    </div>
                    <p className="font-light text-center text-gray-500 dark:text-gray-400 sm:text-lg mt-5 text-justify">
                      We envision a world where education is not a chore but a
                      thrilling exploration. Through our platform, we strive to
                      build a global community that values the synergy of games
                      and learning, fostering creativity, critical thinking, and
                      a love for knowledge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
            Contact Us
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            Got a technical issue? Want to send feedback about a beta feature?
            Need details about our Business plan? Let us know.
          </p>
          <form action="#" className="space-y-8">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="name@flowbite.com"
                required=""
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Let us know how we can help you"
                required=""
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Your message
              </label>
              <textarea
                id="message"
                rows={6}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Leave a comment..."
                defaultValue={""}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                // check if email is valid
                if (email === "") {
                  alert("Please enter your email");
                  return;
                }
                if (subject === "") {
                  alert("Please enter subject");
                  return;
                }
                if (message === "") {
                  alert("Please enter message");
                  return;
                }
                // post to backend
                fetch(process.env.REACT_APP_API + "/send-email", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    email: email,
                    subject: subject,
                    message: message,
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.status === "success") {
                      toast.success("Message sent successfully");
                    } else {
                      toast.error("Failed to send message");
                    }
                  })
                  .catch((err) => {
                    toast.error("Failed to send message");
                  });
              }}
              className="py-3 px-5 text-sm font-bold text-center text-black rounded-lg bg-blue-200 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-white dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Send message
            </button>
          </form>
        </div>
      </section>

      <Footer></Footer>
    </>
  );
};

export default AboutUS;
