import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const settingApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSetting: builder.query({
      query: () => ({
        url: `setting`,
      }),
      providesTags: ["setting"],
    }),

    updateSetting: builder.mutation({
      query: (values) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `setting`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("shift updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating shift");
        }
      },
      invalidatesTags: ["setting"],
    }),
  }),
});

export const { useGetSettingQuery, useUpdateSettingMutation } = settingApi;
