import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-toastify";
import { useGetDesignationsQuery } from "../../../redux/rtk/features/designation/designationApi";
import { useUpdateDesHistoryMutation } from "../../../redux/rtk/features/designationHistory/designationHistoryApi";
import BtnEditSvg from "../Button/btnEditSvg";

const DesignationEditSinglePopup = ({ data, setLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [designationStartDate, setdesignationStartDate] = useState(
    dayjs(data?.startDate).format("YYYY-MM-DD")
  );
  const [designationEndDate, setdesignationEndDate] = useState(
    dayjs(data?.endDate).format("YYYY-MM-DD")
  );
  const [designationComment, setdesignationComment] = useState("");
  const [updateDesHistory, { isLoading, isSuccess }] =
    useUpdateDesHistoryMutation();

  const { data: designations } = useGetDesignationsQuery();
  const [designationId, setDesignationId] = useState(
    data?.designation?.designationId
  );

  const [initialValues, setInitialValues] = useState({
    designationId: data?.designationId || "",
    designationStartDate: dayjs(data?.startDate),
    designationEndDate: dayjs(data?.endDate),
    designationComment: data?.comment,
  });

  const onFinish = async (values) => {
    setLoading(true);
    const id = data.id || "";
    const infoData = {
      ...values,
      designationId: designationId,
      designationComment: designationComment || "",
      designationStartDate: designationStartDate,
      designationEndDate: designationEndDate,
    };

    await updateDesHistory({ id, values: infoData });

    if (isSuccess) {
      setInitialValues({});
      setDesignationId("");
      setdesignationComment("");
      setdesignationStartDate();
      setdesignationEndDate();

      setIsModalOpen(false);
      setLoading(false);
      window.location.reload();
    } else {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding designation");
    setLoading(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setDesignationId("");
    setdesignationComment("");
    setdesignationStartDate();
    setdesignationEndDate();
    setIsModalOpen(false);
    setLoading(false);
  };
  const handleCancel = () => {
    setDesignationId("");
    setdesignationComment("");
    setdesignationStartDate();
    setdesignationEndDate();
    setIsModalOpen(false);
    setLoading(false);
  };

  return (
    <>
      <button onClick={showModal} className='mr-2'>
        <BtnEditSvg size={20} />
      </button>
      <Modal
        title={`Edit Designation ${data?.id}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          style={{ marginBottom: "100px" }}
          eventKey='design-form'
          initialValues={initialValues}
          name='basic'
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <div>
            <Form.Item
              style={{ marginBottom: "10px" }}
              label='Designation'
              name='designationId'
              rules={[
                {
                  required: true,
                  message: "Please input your Designation!",
                },
              ]}
            >
              <Select
                placeholder='Select Designation'
                onChange={(value) => setDesignationId(value)}
              >
                {designations?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name || ""}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label='Start Date'
              name='designationStartDate'
              rules={[
                {
                  required: true,
                  message: "Please input your start date!",
                },
              ]}
            >
              <DatePicker
                name='designationStartDate'
                format='YYYY-MM-DD'
                onChange={(date) => setdesignationStartDate(date)}
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label='End Date'
              name='designationEndDate'
            >
              <DatePicker
                defaultValue={initialValues.designationEndDate}
                onChange={(date) => setdesignationEndDate(date)}
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px" }}
              label='Comment'
              name='designationComment'
            >
              <Input name='designationComment' />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              wrapperCol={{
                offset: 6,
                span: 12,
              }}
            >
              <Button
                type='primary'
                size='small'
                htmlType='submit'
                block
                loading={isLoading}
              >
                Update Now
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default DesignationEditSinglePopup;
