import { Button, Col, Form, Input, Row, Typography } from "antd";

import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import ViewBtn from "../Buttons/ViewBtn";

import { HexColorPicker } from "react-colorful";
import {
  useAddEmploymentStatusMutation,
  useGetEmploymentStatusesQuery,
} from "../../redux/rtk/features/employemntStatus/employmentStatusApi";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import TablePagination from "./../CommonUi/TablePagination";

const AddEmploymentStatus = ({ drawer }) => {
  const { data: list, isLoading } = useGetEmploymentStatusesQuery();
  const [addEmploymentStatus, { isLoading: loading }] =
    useAddEmploymentStatusMutation();
  const [color, setColor] = useState("#ffffff");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const { Title } = Typography;
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const FormData = {
      ...values,
      colourValue: color,
    };

    const resp = await addEmploymentStatus(FormData);

    if (resp) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding shift");
  };
  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Color Code",
      dataIndex: "colourValue",
      key: "colourValue",
      render: (colourValue) => (
        <div className='flex'>
          <div
            className='rounded border border-gray-200'
            style={{
              marginRight: "10px",
              width: "20px",
              height: "20px",
              backgroundColor: colourValue,
            }}
          ></div>
          {colourValue}
        </div>
      ),
    },

    {
      id: 4,
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      id: 5,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => <ViewBtn path={`/admin/employment-status/${id}/`} />,
    },
  ];
  return (
    <Fragment>
      <UserPrivateComponent permission={"create-employmentStatus"}>
        <Row className='mr-top' justify={drawer ? "center" : "space-between"}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={drawer ? 22 : 16}
            xl={drawer ? 22 : 12}
            className='column-design border rounded card-custom'
          >
            <Title level={4} className='m-2 mt-5 mb-5 text-center'>
              Add Employment Status
            </Title>
            <Form
              form={form}
              style={{ marginBottom: "40px" }}
              eventKey='shift-form'
              name='basic'
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 12,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <div>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: "Please input your shift!",
                    },
                  ]}
                >
                  <Input placeholder='Parmanet' />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Color Code'
                  name='colourValue'
                >
                  <Input
                    placeholder='#00FF00'
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    onClick={() => setShowColorPicker(true)}
                  />
                  {showColorPicker && (
                    <div className='flex justify-between mt-3 mb-3'>
                      <HexColorPicker
                        onChange={(i) => setColor(i)}
                        color={color}
                      />
                      <Button
                        type='danger'
                        onClick={() => setShowColorPicker(false)}
                      >
                        Close
                      </Button>
                    </div>
                  )}
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "20px" }}
                  label='Description'
                  name={"description"}
                >
                  <Input.TextArea placeholder='Description' />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  wrapperCol={{
                    offset: 6,
                    span: 12,
                  }}
                >
                  <Button
                    type='primary'
                    size='large'
                    block
                    htmlType='submit'
                    loading={loading}
                  >
                    Add Employment Status
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </UserPrivateComponent>
      <hr />

      {drawer || (
        <TablePagination
          columns={columns}
          list={list}
          loading={isLoading}
          csvFileName={"employment status"}
          permission={"readAll-employmentStatus"}
        />
      )}
    </Fragment>
  );
};

export default AddEmploymentStatus;
