import { toast } from "react-toastify";
import { apiSlice } from "../../../api/apiSlice";

export const taskPriorityApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTaskPriorities: builder.query({
      query: () => ({
        url: `task-priority?query=all`,
      }),
      providesTags: ["TaskPriorities"],
    }),

    getTaskPriority: builder.query({
      query: (id) => ({
        url: `task-priority/${id}`,
      }),
      providesTags: ["TaskPriority"],
    }),

    addTaskPriority: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `task-priority/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Task-priority added successfully");
        } catch (err) {
          toast.error("An error occurred while adding Task-priority");
        }
      },
      invalidatesTags: ["TaskPriority", "TaskPriorities"],
    }),

    updateTaskPriority: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `task-priority/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("TaskPriority updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating TaskPriority");
        }
      },
      invalidatesTags: ["TaskPriority", "TaskPriorities"],
    }),

    deleteTaskPriority: builder.mutation({
      query: (id) => ({
        url: `task-priority/${id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted TaskPriority successful");
        } catch (err) {
          toast.error("An error occurred while deleting the TaskPriority");
        }
      },
      invalidatesTags: ["TaskPriority", "TaskPriorities"],
    }),
  }),
});

export const {
  useGetTaskPrioritiesQuery,
  useGetTaskPriorityQuery,
  useAddTaskPriorityMutation,
  useUpdateTaskPriorityMutation,
  useDeleteTaskPriorityMutation,
} = taskPriorityApi;
