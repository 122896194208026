import { toast } from "react-toastify";
import { apiSlice } from "./../../../api/apiSlice";

export const taskStatusApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTaskStatus: builder.query({
      query: ({ status }) => ({
        url: `task-status?status=${status}`,
      }),
      providesTags: ["TaskStatusAll"],
    }),

    getAllTaskStatusByProjectId: builder.query({
      query: (id) => ({
        url: `task-status/${id}/project`,
      }),
      providesTags: ["TaskStatusById"],
    }),

    getTaskStatus: builder.query({
      query: (id) => ({
        url: `task-status/${id}`,
      }),
      providesTags: ["TaskStatus"],
    }),

    addTaskStatus: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `task-status/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Task-status added successfully");
        } catch (err) {
          toast.error("An error occurred while adding Task-status");
        }
      },
      invalidatesTags: ["TaskStatus", "TaskStatusAll", "TaskStatusById"],
    }),

    updateTaskStatus: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `task-status/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Task Status updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Task Status");
        }
      },
      invalidatesTags: [
        "TaskStatus",
        "TaskStatus",
        "TaskStatusAll",
        "TaskStatusById",
      ],
    }),

    updateTaskStatusId: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `task-status/${id}?query=taskStatus`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Task Status updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Task Status");
        }
      },
      invalidatesTags: [
        "TaskStatus",
        "TaskStatus",
        "TaskStatusAll",
        "TaskStatusById",
      ],
    }),

    deleteTaskStatus: builder.mutation({
      query: (id) => ({
        url: `task-status/${id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted Task Status successful");
        } catch (err) {
          toast.error("An error occurred while deleting the Task Status");
        }
      },
      invalidatesTags: [
        "TaskStatus",
        "TaskStatus",
        "TaskStatusAll",
        "TaskStatusById",
      ],
    }),
  }),
});

export const {
  useGetAllTaskStatusQuery,
  useGetAllTaskStatusByProjectIdQuery,
  useGetTaskStatusQuery,
  useAddTaskStatusMutation,
  useUpdateTaskStatusMutation,
  useUpdateTaskStatusIdMutation,
  useDeleteTaskStatusMutation,
} = taskStatusApi;
