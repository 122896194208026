import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const awardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAwards: builder.query({
      query: () => ({
        url: `award?query=all`,
      }),
      providesTags: ["Awards"],
    }),

    getAward: builder.query({
      query: (id) => ({
        url: `award/${id}`,
      }),
      providesTags: ["Award"],
    }),

    addAward: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `award/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Award added successfully");
        } catch (err) {
          toast.error("An error occurred while adding Award");
        }
      },
      invalidatesTags: ["Awards", "Award"],
    }),

    updateAward: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `award/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;

          toast.success("Award updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Award");
        }
      },
      invalidatesTags: ["Awards", "Award"],
    }),

    deleteAward: builder.mutation({
      query: (id) => ({
        url: `award/${id}`,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted Award successful");
        } catch (err) {
          toast.error("An error occurred while deleting the Award");
        }
      },
      invalidatesTags: ["Awards", "Award"],
    }),
  }),
});

export const {
  useGetAwardsQuery,
  useGetAwardQuery,
  useAddAwardMutation,
  useUpdateAwardMutation,
  useDeleteAwardMutation,
} = awardApi;
