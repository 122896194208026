import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const roleApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => ({
        url: `role?query=all`,
      }),
      providesTags: ["Roles"],
    }),

    getRole: builder.query({
      query: (id) => ({
        url: `role/${id}`,
      }),
      providesTags: ["Role"],
    }),

    addRole: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `role`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("role added successfully");
        } catch (err) {
          toast.error("An error occurred while adding role");
        }
      },
      invalidatesTags: ["Roles"],
    }),

    getPermission: builder.query({
      query: () => ({
        url: `permission?query=all`,
      }),
      providesTags: ["Permissions"],
    }),

    addPermission: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `role-permission`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("role Permission successfully");
        } catch (err) {
          toast.error("An error occurred while adding Permission");
        }
      },
      invalidatesTags: ["Permissions"],
    }),

    deletePermissions: builder.mutation({
      query: (value) => ({
        url: `role-permission?query=deletemany`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: value,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted Permissions successful");
        } catch (err) {
          toast.error("An error occurred while deleting the Permissions");
        }
      },
      invalidatesTags: ["Permissions"],
    }),
  }),
});

export const {
  useGetRoleQuery,
  useGetRolesQuery,
  useAddRoleMutation,
  useGetPermissionQuery,
  useAddPermissionMutation,
  useDeletePermissionsMutation,
} = roleApi;
