import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const PublicHolidayApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicHolidays: builder.query({
      query: () => ({
        url: `public-holiday?query=all`,
      }),
      providesTags: ["PublicHolidays"],
    }),

    getPublicHoliday: builder.query({
      query: (id) => ({
        url: `public-holiday/${id}`,
      }),
    }),

    addPublicHoliday: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `public-holiday/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("PublicHoliday added successfully");
        } catch (err) {
          toast.error("An error occurred while adding PublicHoliday");
        }
      },
      invalidatesTags: ["PublicHolidays"],
    }),

    updatePublicHoliday: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `public-holiday//${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("PublicHoliday updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating PublicHoliday");
        }
      },
      invalidatesTags: ["PublicHolidays"],
    }),

    deletePublicHoliday: builder.mutation({
      query: (id) => ({
        url: `public-holiday/${id}`,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted PublicHoliday successful");
        } catch (err) {
          toast.error("An error occurred while deleting the PublicHoliday");
        }
      },
      invalidatesTags: ["PublicHolidays"],
    }),
  }),
});

export const {
  useGetPublicHolidaysQuery,
  useGetPublicHolidayQuery,
  useAddPublicHolidayMutation,
  useUpdatePublicHolidayMutation,
  useDeletePublicHolidayMutation,
} = PublicHolidayApi;
