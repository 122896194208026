import { toast } from "react-toastify";
import { apiSlice } from "../../../api/apiSlice";

export const projectApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: () => ({
        url: `project?query=all`,
      }),
      providesTags: ["ProjectAll"],
    }),

    getProjectsByStatus: builder.query({
      query: (status) => ({
        url: `project?status=${status}`,
      }),
      providesTags: ["Projects"],
    }),

    getProject: builder.query({
      query: (id) => ({
        url: `project/${id}`,
      }),
      providesTags: ["Project"],
    }),

    addProject: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `project/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Project added successfully");
        } catch (err) {
          toast.error("An error occurred while adding Project");
        }
      },
      invalidatesTags: ["Projects", "ProjectAll"],
    }),

    updateProject: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `project/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        // pessimistic user cache update
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData("getProject", arg.id, () => data)
          );
          toast.success("Project updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Project");
        }
      },
      invalidatesTags: ["Projects", "ProjectAll"],
    }),

    deleteProject: builder.mutation({
      query: (id) => ({
        url: `project/${id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        // pessimistic user cache update
        try {
          await queryFulfilled;

          toast.success("Deleted Project successful");
        } catch (err) {
          toast.error("An error occurred while deleting the Project");
        }
      },
      invalidatesTags: ["Projects", "Project", "ProjectAll"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectsByStatusQuery,
  useGetProjectQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectApi;
