import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiOutlineShoppingBag } from "react-icons/hi";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const isLogged = Boolean(localStorage.getItem("isLogged"));
  const [navIsopen, setNavIsopen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div class={`bars ${navIsopen ? "active" : ""}`} id="nav-action"
        onClick={() => setNavIsopen(!navIsopen)}>
        <span class="bar"> </span>
      </div>

      <nav id="nav"
        class={`${navIsopen ? "visible" : ""}`}>
        <ul>
          <li class="shape-circle circle-one">
            {!isLogged ? (
              <Link
                to="/admin/auth/login"
              >
                Login
              </Link>
            ) : (
              <Link
                to="/admin/dashboard"
              >
                Dashboard
              </Link>
            )}
          </li>
          <li class="shape-circle circle-one">
            <Link
              to="/Blog"
            >
              Blog
            </Link>
          </li>
          <li class="shape-circle circle-three">
            <Link
              to="/about-us"
            >
              About us
            </Link>
          </li>
          <li class="shape-circle circle-five">
            <a href="/">Home</a>
          </li>
        </ul>
      </nav>
      {/* <section
      className="w-full px-8 text-gray-700 bg-[#292E31]"
      style={{
        position: "sticky",
        top: "0",
        zIndex: "100",
        backgroundColor: "white",
        boxShadow: "0 2px 10px -4px #292E31",
      }}
    >
      <nav className="bg-[#292E31] border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <h2 className="text-3xl text-white font-bold uppercase">PG2L</h2>
          <button
            data-collapse-toggle="navbar-dropdown"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-dropdown"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className="hidden w-full lg:block lg:w-auto"
            id="navbar-dropdown"
          >
            <ul className="flex flex-col items-center justify-center text-white bg-[#292E31] font-medium p-4 lg:p-0 m-auto border rounded-lg lg:flex-row lg:space-x-4 lg:mt-0 lg:border-0  sm:text-white">
              <li>
                <Link
                  to="/"
                  className={`inline-flex items-center justify-center px-1 py-1 text-xl font-bold leading-6 whitespace-no-wrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 hover:text-[#FF6700]`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className={`inline-flex items-center justify-center px-1 py-1 text-xl font-bold leading-6 whitespace-no-wrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 hover:text-[#FF6700]`}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/Blog"
                  className={`inline-flex items-center justify-center px-1 py-1 text-xl font-bold leading-6 whitespace-no-wrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 hover:text-[#FF6700]`}
                >
                  Blog
                </Link>
              </li>
              {!isLogged ? (
                <Link
                  to="/admin/auth/login"
                  className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                >
                  Login
                </Link>
              ) : (
                <Link
                  to="/admin/dashboard"
                  className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-teal-600 border border-transparent rounded-md shadow-sm hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600"
                >
                  Dashboard
                </Link>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </section> */}
    </>
  );
};

export default Navbar;
