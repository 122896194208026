import { Card } from "antd";
import dayjs from "dayjs";
import { useGetAwardsQuery } from "../../redux/rtk/features/award/awardApi";
import ViewBtn from "../Buttons/ViewBtn";
import TablePagination from "../CommonUi/TablePagination";
import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";

function GetAllAward() {
  const { data: list, isLoading } = useGetAwardsQuery();

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      id: 3,
      title: "Created at",
      dataIndex: "createdAt",
      key: "addrcreatedAtess",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD"),
    },
    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => <ViewBtn path={`/admin/award/${id}/`} />,
    },
  ];

  return (
    <>
      <PageTitle title='Back' />
      {!isLoading ? (
        <Card className='mt-5'>
          <div className='text-center my-2 flex justify-between'>
            <h5 className='department-list-title text-color-2 text-xl mb-2'>
              Award List
            </h5>
          </div>

          <TablePagination
            csvFileName={"Award"}
            loading={isLoading}
            columns={columns}
            list={list}
            permission={"readAll-award"}
          />
        </Card>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default GetAllAward;
