import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const designationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDesignations: builder.query({
      query: () => ({
        url: `designation?status=true`,
      }),
      providesTags: ["Designations"],
    }),

    getDesignationByEmployee: builder.query({
      query: () => ({
        url: `designation/employee`,
      }),
      providesTags: ["DesignationByEmployee"],
    }),

    getDesignation: builder.query({
      query: (id) => ({
        url: `designation/employee/${id}`,
      }),
      providesTags: ["Designation"],
    }),

    addDesignation: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `designation/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Designations added successfully");
        } catch (err) {
          toast.error("An error occurred while adding Designations");
        }
      },
      invalidatesTags: ["Designations", "DesignationByEmployee"],
    }),

    updateDesignation: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `designation/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Designations updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Designations");
        }
      },
      invalidatesTags: ["Designations", "Designation", "DesignationByEmployee"],
    }),

    deleteDesignation: builder.mutation({
      query: (id) => ({
        url: `designation/${id}`,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted Designations successful");
        } catch (err) {
          toast.error("An error occurred while deleting the Designations");
        }
      },
      invalidatesTags: ["Designations", "Designation", "DesignationByEmployee"],
    }),
  }),
});

export const {
  useGetDesignationsQuery,
  useGetDesignationByEmployeeQuery,
  useGetDesignationQuery,
  useAddDesignationMutation,
  useUpdateDesignationMutation,
  useDeleteDesignationMutation,
} = designationApi;
