import { Card } from "antd";
import { Navigate } from "react-router-dom";
import {
  taskPriorityApi,
  useGetTaskPrioritiesQuery,
} from "../../../redux/rtk/features/projectManagement/project/taskPriority/taskPriorityApi";
import UpdateBtn from "../../Buttons/UpdateBtn";
import CommonDelete from "../../CommonUi/CommonDelete";
import PageTitle from "../../page-header/PageHeader";
import TablePagination from "./../../CommonUi/TablePagination";
import AddTaskPriority from "./AddtaskPriority";

const TaskStatus = (props) => {
  const isLogged = Boolean(localStorage.getItem("isLogged"));
  const { isLoading, data: list } = useGetTaskPrioritiesQuery();

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }
  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      key: "name",
      render: ({ name }) => name.toUpperCase(),
    },
    {
      id: 3,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => (
        <div className='flex justify-start'>
          <div className='flex justify-start'>
            <UpdateBtn path={`/admin/task-priority/update/${id}`} />
            <CommonDelete
              permission={"delete-priority"}
              id={id}
              deleteThunk={
                taskPriorityApi.endpoints.deleteTaskPriority.initiate
              }
            />
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <PageTitle title='Back' />
      <AddTaskPriority list={list} loading={isLoading} />
      <Card className='mb-4'>
        <h1 className='text-xl mb-4'> Task Priority Column List </h1>
        <TablePagination
          loading={isLoading}
          columns={columns}
          list={list}
          csvFileName={"task priorities"}
          permission={"readAll-priority"}
        />
      </Card>
    </div>
  );
};

export default TaskStatus;
