import React from "react";
import { useNavigate } from "react-router-dom";

const BlogSidebar = () => {
  const navigate = useNavigate();
  return (
    <div class="flex flex-col lg:w-3/12 w-full bg-gray-100">

      <ul class="py-4">
        <li class="px-6 text-gray-900 text-xl">Popular Post:</li>
        <li class="px-2 text-gray-900">
          <ul class="py-1">
            <li class="px-1 my-1 text-gray-900 cursor-pointer">
              <div
                class="w-full cursor-pointer"
                onClick={() => {
                  navigate("/Blog/1");
                }}
              >
                {/* TO DO */}
                {/*
                Image na thakle pbl
                */}
                {/* TO DO */}
                <div class="flex bg-white rounded-lg shadow-lg overflow-hidden justify-center items-center">
                  {/* <img
                    src="/images/blog1.jpeg"
                    alt="Blog post"
                    class="w-AUTO h-16 object-cover"
                  /> */}
                  <div class="p-2">
                    <h3 class="text-xl font-bold mb-2">
                      Transforming Accounting Education into a Mobile Game Empire
                    </h3>
                  </div>
                </div>
              </div>
            </li>
            <li class="px-1 my-1 text-gray-900 cursor-pointer">
              <div
                class="w-full cursor-pointer"
                onClick={() => {
                  navigate("/Blog/2");
                }}
              >
                <div class="flex bg-white rounded-lg shadow-lg overflow-hidden justify-center items-center">
                  {/* <img
                    src="/images/blog2.jpeg"
                    alt="Blog post"
                    class="w-AUTO h-16 object-cover"
                  /> */}
                  <div class="p-2">
                    <h3 class="text-xl font-bold mb-2">
                      Tired of dry textbooks and passive learning?
                    </h3>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default BlogSidebar;
