import { Button, Col, Form, Input, Row, Typography } from "antd";

import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { useAddAwardMutation } from "../../redux/rtk/features/award/awardApi";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import PageTitle from "../page-header/PageHeader";

const AddAward = ({ drawer }) => {
  const [form] = Form.useForm();
  const [addSingleAward, { isLoading }] = useAddAwardMutation();
  const { Title } = Typography;

  const onFinish = async (values) => {
    const resp = await addSingleAward(values);

    if (resp) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
  };
  return (
    <Fragment bordered={false}>
      <PageTitle title='Back' />

      <UserPrivateComponent permission={"create-award"}>
        <Row className='mr-top' justify={drawer ? "center" : "center"}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={drawer ? 22 : 16}
            xl={drawer ? 22 : 12}
            className='column-design border rounded card-custom'
          >
            <Title level={4} className='m-2 mt-5 mb-5 text-center'>
              Add Award
            </Title>
            <Form
              style={{ marginBottom: "40px" }}
              form={form}
              eventKey='department-form'
              name='basic'
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 12,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <div>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: "Please input your award name!",
                    },
                  ]}
                >
                  <Input placeholder='Employee Of The Month' />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "20px" }}
                  label='Description'
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: "Please input your award description!",
                    },
                  ]}
                >
                  <Input placeholder='Employee Who Performed Well' />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  wrapperCol={{
                    offset: 6,
                    span: 12,
                  }}
                >
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    block
                    loading={isLoading}
                  >
                    Add New Award
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </UserPrivateComponent>
      <hr />
    </Fragment>
  );
};

export default AddAward;
