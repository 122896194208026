import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import styles from "./AddTransaction.module.css";

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useGetAccountsQuery } from "../../redux/rtk/features/account/accountApi";
import { useAddTransactionMutation } from "../../redux/rtk/features/transaction/transactionApi";
import BigDrawer from "../Drawer/BigDrawer";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import AddAccount from "../account/AddAccount";
import PageTitle from "../page-header/PageHeader";

const AddTransaction = () => {
  const navigate = useNavigate();

  const [addTransaction, { isLoading, isSuccess }] =
    useAddTransactionMutation();
  const { data: accounts } = useGetAccountsQuery();
  const { Title } = Typography;
  const [form] = Form.useForm();

  let [date, setDate] = useState(dayjs());

  const [account, setAccount] = useState({
    debit_id: 0,
    credit_id: 0,
  });

  const onFinish = async (values) => {
    const data = {
      ...values,
      date: date,
      amount: parseInt(values.amount),
      debit_id: account.debit_id,
      credit_id: account.credit_id,
    };

    await addTransaction(data);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/admin/transaction/");
    }
  }, [isSuccess, navigate]);

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <>
      <PageTitle title='Back' />
      <UserPrivateComponent permission={"create-transaction"}>
        <Row className='mr-top'>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={12}
            className='column-design border rounded bg-white'
          >
            <Card bordered={false}>
              <Title level={3} className='m-2 mb-4 text-center'>
                Transaction
              </Title>
              <Form
                form={form}
                name='basic'
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
              >
                <Form.Item label='Date' required>
                  <DatePicker
                    defaultValue={dayjs("2015/01/01", "YYYY/MM/DD")}
                    format={"YYYY/MM/DD"}
                    onChange={(value) => setDate(value?._d)}
                    label='date'
                    name='date'
                    className='date-picker date-picker-transaction-create'
                    rules={[
                      {
                        required: true,
                        message: "Please input date!",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name='debit_id'
                  label='Debit Account'
                >
                  <Space.Compact block>
                    <Select
                      onChange={(value) =>
                        setAccount({ ...account, debit_id: value })
                      }
                      loading={!accounts}
                      showSearch
                      placeholder='Select Debit ID'
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {accounts &&
                        accounts.map((acc) => (
                          <Select.Option key={acc.id} value={acc.id}>
                            {acc.name}
                          </Select.Option>
                        ))}
                    </Select>
                    <BigDrawer
                      title={"new debit account"}
                      btnTitle={"Debit account"}
                      children={<AddAccount drawer={true} />}
                    />
                  </Space.Compact>
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name='credit_id'
                  label='Credit Account'
                >
                  <Space.Compact block>
                    <Select
                      onChange={(value) =>
                        setAccount({ ...account, credit_id: value })
                      }
                      loading={!accounts}
                      showSearch
                      placeholder='Select Credit ID'
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {accounts &&
                        accounts.map((acc) => (
                          <Select.Option key={acc.id} value={acc.id}>
                            {acc.name}
                          </Select.Option>
                        ))}
                    </Select>
                    <BigDrawer
                      title={"new credit account"}
                      btnTitle={"Credit account"}
                      children={<AddAccount drawer={true} />}
                    />
                  </Space.Compact>
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Amount'
                  name='amount'
                  rules={[
                    {
                      required: true,
                      message: "Please input amount!",
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Particulars'
                  name='particulars'
                  rules={[
                    {
                      required: true,
                      message: "Please input particulars!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  className={styles.payNowBtnContainer}
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    shape='round'
                    size='large'
                    loading={isLoading}
                  >
                    Pay Now
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </UserPrivateComponent>
    </>
  );
};

export default AddTransaction;
