import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const awardHistoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAwardHistorys: builder.query({
      query: () => ({
        url: `awardHistory?query=all`,
      }),
      providesTags: ["AwardHistory"],
    }),

    getAwardHistory: builder.query({
      query: (id) => ({
        url: `awardHistory/${id}`,
      }),
    }),

    addAwardHistory: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `awardHistory/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Award History added successfully");
        } catch (err) {
          toast.error("An error occurred while adding Award History");
        }
      },
      invalidatesTags: ["AwardHistory", "User"],
    }),

    updateAwardHistory: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `awardHistory/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Award History updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Award History");
        }
      },
      invalidatesTags: ["AwardHistory", "User", "Awards"],
    }),

    deleteAwardHistory: builder.mutation({
      query: (id) => ({
        url: `awardHistory/${id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted Award History successful");
        } catch (err) {
          toast.error("An error occurred while deleting the Award History");
        }
      },
      invalidatesTags: ["AwardHistory", "User"],
    }),
  }),
});

export const {
  useGetAwardHistorysQuery,
  useGetAwardHistoryQuery,
  useAddAwardHistoryMutation,
  useUpdateAwardHistoryMutation,
  useDeleteAwardHistoryMutation,
} = awardHistoryApi;
