import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const departmentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDepartments: builder.query({
      query: () => ({
        url: `department?query=all`,
      }),
      providesTags: ["Departments"],
    }),

    getDepartment: builder.query({
      query: (id) => ({
        url: `department/${id}`,
      }),
      providesTags: ["Department"],
    }),

    addDepartment: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `department`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Department completed successfully");
        } catch (err) {
          toast.error("An error occurred while Department");
        }
      },
      invalidatesTags: ["Departments"],
    }),

    updateDepartment: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `department/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Department updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Department");
        }
      },
      invalidatesTags: ["Departments", "Department"],
    }),

    deleteDepartment: builder.mutation({
      query: (id) => ({
        url: `department/${id}`,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted Department successful");
        } catch (err) {
          toast.error("An error occurred while deleting the Department");
        }
      },
      invalidatesTags: ["Departments", "Department"],
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useGetDepartmentQuery,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentApi;
