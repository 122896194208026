import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const weeklyHolidayApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWeeklyHolidays: builder.query({
      query: () => ({
        url: `weekly-holiday?query=all`,
      }),
      providesTags: ["WeeklyHolidays"],
    }),

    getWeeklyHoliday: builder.query({
      query: (id) => ({
        url: `weekly-holiday/${id}`,
      }),
      providesTags: ["WeeklyHoliday"],
    }),

    addWeeklyHoliday: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `weekly-holiday/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("weeklyHoliday added successfully");
        } catch (err) {
          toast.error("An error occurred while adding weeklyHoliday");
        }
      },
      invalidatesTags: ["weeklyHolidays"],
    }),

    updateWeeklyHoliday: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `weekly-holiday/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("weeklyHoliday updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating weeklyHoliday");
        }
      },
      invalidatesTags: ["WeeklyHoliday", "WeeklyHolidays"],
    }),

    deleteWeeklyHoliday: builder.mutation({
      query: (id) => ({
        url: `weekly-holiday/${id}`,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted weeklyHoliday successful");
        } catch (err) {
          toast.error("An error occurred while deleting the weeklyHoliday");
        }
      },
      invalidatesTags: ["WeeklyHoliday", "WeeklyHolidays"],
    }),
  }),
});

export const {
  useGetWeeklyHolidayQuery,
  useGetWeeklyHolidaysQuery,
  useAddWeeklyHolidayMutation,
  useUpdateWeeklyHolidayMutation,
  useDeleteWeeklyHolidayMutation,
} = weeklyHolidayApi;
