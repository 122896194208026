
const WirelessFreedom = () => {
    return (
        <div className="flex flex-col md:flex-row w-full bg-slate-100 ">

            <div className='md:w-7/12 w-full relative'>
                <img className='w-full' src="img/fedom.jpg" alt="" />
                <div className='absolute top-3/4 md:left-1/4 lg:left-75% xl:left-1/3 2xl:left-1/2 whitespace-nowrap mr-5 transform -translate-y-1/4  text-white  bg-gray-800 lg:py-2 lg:pl-10 xl:py-8 px-5 py-1'> 
                    <h2 className='text-lg font-bold'>
                    Tired of dry textbooks and passive learning?
                    </h2>
                    <p className='mt-3'>Be Billionaire</p>

                </div>
            </div>
            <div className="bg-[#ECECEC] md:w-5/12 w-full flex justify-center items-center  text-black ">
                <div className='flex flex-col  max-w-screen-sm  mx-auto py-5 md:py-0 px-3'>
                    <h1 className='text-2xl font-semibold'>Transforming Accounting Education  <br /> into a Mobile Game Empire</h1>
                    <p className='mt-7 text-lg font-medium text-gray-600 text-justify'>Throws open the doors to a dynamic real estate world where you're the boss! This mobile game revolutionizes accounting education by turning theory into an immersive, action-packed business simulation.
.</p>
                </div>
            </div>

        </div>
    );
};

export default WirelessFreedom;