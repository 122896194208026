import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const shiftApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getShifts: builder.query({
            query: () => ({
                url: `shift?status=true`,
            }),
            providesTags: ["Shifts"],
        }),

        getShift: builder.query({
            query: (id) => ({
                url: `shift/${id}`,
            }),
            providesTags: ["Shift"]
        }),

        addShift: builder.mutation({
            query: (values) => ({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `shift/`,
                body: values,
            }),

            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    toast.success("shift added successfully");
                } catch (err) {
                    toast.error("An error occurred while adding shift");
                }
            },
            invalidatesTags: ["Shifts"],
        }),

        updateShift: builder.mutation({
            query: ({ id, values }) => ({
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `shift/${id}`,
                body: values,
            }),

            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    toast.success("shift updated successfully");
                } catch (err) {
                    toast.error("An error occurred while updating shift");
                }
            },
            invalidatesTags: ["Shift", "Shifts"],
        }),

        deleteShift: builder.mutation({
            query: (id) => ({
                url: `shift/${id}`,
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: {
                    status: false,
                },
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                    toast.success("Deleted shift successful");
                } catch (err) {
                    toast.error("An error occurred while deleting the shift");
                }
            },
            invalidatesTags: ["Shift", "Shifts"],
        }),
    }),
});

export const {
    useGetShiftQuery,
    useGetShiftsQuery,
    useAddShiftMutation,
    useDeleteShiftMutation,
    useUpdateShiftMutation,
} = shiftApi;
