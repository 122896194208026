import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const DesHistoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDesignationHistories: builder.query({
      query: () => ({
        url: `designationHistory?status=true&page=1&count=20`,
      }),
      providesTags: ["DesignationHistories"],
    }),

    getDesHistory: builder.query({
      query: (id) => ({
        url: `designationHistory/${id}`,
      }),
      providesTags: ["DesignationHistory"],
    }),

    addDesHistory: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `designationHistory`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Designation History added successfully");
        } catch (err) {
          toast.error("An error occurred while adding Designation History");
        }
      },
      invalidatesTags: ["DesignationHistories", "User"],
    }),

    updateDesHistory: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `designationHistory/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Designation History updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Designation History");
        }
      },
      invalidatesTags: ["DesignationHistories", "DesignationHistory", "User"],
    }),

    deleteDesHistory: builder.mutation({
      query: (id) => ({
        url: `designationHistory/${id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted Designation History successful");
        } catch (err) {
          toast.error(
            "An error occurred while deleting the Designation History"
          );
        }
      },
      invalidatesTags: ["DesignationHistories", "DesignationHistory", "User"],
    }),
  }),
});

export const {
  useGetDesHistoryQuery,
  useGetDesignationHistoriesQuery,
  useAddDesHistoryMutation,
  useUpdateDesHistoryMutation,
  useDeleteDesHistoryMutation,
} = DesHistoryApi;
