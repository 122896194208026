import { DollarCircleFilled, EyeFilled } from "@ant-design/icons";
import { Button, Card, DatePicker, Radio, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";

import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddPaymentMutation } from "../../redux/rtk/features/payment/paymentApi";
import { useGetPayslipForPaymentMonthWiseQuery } from "../../redux/rtk/features/payroll/payrollApi";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import PageTitle from "../page-header/PageHeader";

function CustomTable({ list, loading }) {
  const [columnsToShow, setColumnsToShow] = useState([]);
  const [addPayslipPayment, { isLoading }] = useAddPaymentMutation();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "user",
      render: (user) => `${user?.firstName} ${user?.lastName}`,
    },

    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "Salary Payable",
      dataIndex: "salaryPayable",
      key: "salaryPayable",
    },
    {
      title: "Month ",
      key: "month",
      render: ({ salaryMonth }) => `${dayjs(salaryMonth, "M").format("MMM")}`,
    },
    {
      title: "Year",
      key: "year",
      render: ({ salaryYear }) => `${salaryYear}`,
    },

    {
      title: "bonus",
      dataIndex: "bonus",
      key: "bonus",
    },

    {
      title: "bonusComment",
      dataIndex: "bonusComment",
      key: "bonusComment",
    },

    {
      title: "deduction",
      dataIndex: "deduction",
      key: "deduction",
    },

    {
      title: "deductionComment",
      dataIndex: "deductionComment",
      key: "deductionComment",
    },

    {
      title: "Total",
      dataIndex: "totalPayable",
      key: "totalPayable",
    },

    {
      title: "W Hours",
      dataIndex: "workingHour",
      key: "workingHour",
      render: (workingHour) => `${workingHour?.toFixed(2)} hours`,
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Action",
      key: "action",
      render: ({ id, paymentStatus }) => {
        const onPayment = async () => {
          addPayslipPayment(id);
        };

        return (
          <div flex justify-between>
            <Link to={`/admin/payroll/${id}`}>
              <Tooltip title='View'>
                <Button
                  icon={<EyeFilled />}
                  type='primary'
                  size='middle'
                  className='mr-2'
                ></Button>
              </Tooltip>
            </Link>

            <UserPrivateComponent permission='create-transaction'>
              <Tooltip title='Payment'>
                <Button
                  loading={isLoading}
                  icon={<DollarCircleFilled />}
                  type='primary'
                  size='middle'
                  onClick={onPayment}
                  disabled={paymentStatus === "PAID"}
                ></Button>
              </Tooltip>
            </UserPrivateComponent>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  return (
    <div className='mt-5'>
      <div className='text-center my-2 flex justify-between'>
        {list && (
          <div style={{ marginBottom: "30px" }}>
            <ColVisibilityDropdown
              options={columns}
              columns={columns}
              columnsToShowHandler={columnsToShowHandler}
            />
          </div>
        )}

        {list && (
          <div>
            <CsvLinkBtn>
              <CSVLink
                data={list}
                className='btn btn-dark btn-sm mb-1'
                filename='payslips'
              >
                Download CSV
              </CSVLink>
            </CsvLinkBtn>
          </div>
        )}
      </div>

      <Table
        scroll={{ x: true }}
        loading={loading || isLoading}
        columns={columnsToShow}
        dataSource={list ? addKeys(list) : []}
      />
    </div>
  );
}

const PayslipList = () => {
  const [pageConfig, setPageConfig] = useState();
  const { data: payroll, isLoading } =
    useGetPayslipForPaymentMonthWiseQuery(pageConfig);

  const onMonthChange = (date, dateString) => {
    setPageConfig((prev) => {
      return { ...prev, value: "monthWise", salaryMonth: dateString };
    });
  };

  const onYearChange = (date, dateString) => {
    setPageConfig((prev) => {
      return { ...prev, value: "monthWise", salaryYear: dateString };
    });
  };

  const options = [
    {
      label: "ALL",
      value: "ALL",
    },
    {
      label: "PAID",
      value: "PAID",
    },
    {
      label: "UNPAID",
      value: "UNPAID",
    },
  ];

  const onChange4 = ({ target: { value } }) => {
    setPageConfig((prev) => {
      return { ...prev, value: "monthWise", paymentStatus: value };
    });
  };

  return (
    <div>
      <PageTitle title='Back' />
      <UserPrivateComponent permission='readAll-payroll'>
        <Card className='mt-5'>
          <div className='flex justify-end'>
            <h1 className='text-base text-color-2 items-center mr-2 mt-1'>
              {" "}
              Select Month :{" "}
            </h1>
            <DatePicker
              format={"M"}
              className=' mr-5'
              style={{ maxWidth: "200px" }}
              picker='month'
              onChange={onMonthChange}
            />
            <h1 className='text-base text-color-2 items-center mr-2 mt-1'>
              {" "}
              Select Year :{" "}
            </h1>
            <DatePicker
              format={"YYYY"}
              picker='year'
              style={{ maxWidth: "200px" }}
              onChange={onYearChange}
            />
            <Radio.Group
              className='ml-3 mr-3'
              options={options}
              onChange={onChange4}
              value={pageConfig?.paymentStatus || "ALL"}
              optionType='button'
              buttonStyle='solid'
            />
          </div>

          <CustomTable list={payroll} loading={isLoading} />
        </Card>
      </UserPrivateComponent>
    </div>
  );
};

export default PayslipList;
