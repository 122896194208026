import React from "react";
import { Link } from "react-router-dom";
import Banner from "./Home/Banner/Banner";
import Accessories from "./Home/Accessories/Accessories";
import Adds from "./Home/Adds/Adds";
import Discover from "./Home/Discover/Discover";
import Future from "./Home/Future/Future";
import OculusQuest from "./Home/OculusQuest/OculusQuest";
import WirelessFreedom from "./Home/WirelessFreedom/WirelessFreedom";
import Navbar from "./Home/Navbar";
import Footer from "./Home/Footer";

const WelcomePage = () => {
  const isLogged = Boolean(localStorage.getItem("isLogged"));

  return (
    <>
      <Navbar></Navbar>
      <Banner></Banner>
      <Future></Future>
      <Adds></Adds>
      <WirelessFreedom></WirelessFreedom>
      <OculusQuest></OculusQuest>
      <Footer></Footer>
    </>
  );
};

export default WelcomePage;
