import { MdOutlinePlayCircleOutline } from "react-icons/md";
import Modal from "./Modal";
import { PiEye, PiHeadphonesLight } from "react-icons/pi";
import { AiOutlineWifi } from "react-icons/ai";
import { CgPerformance } from "react-icons/cg";
import TextTransition, { presets } from "react-text-transition";
import { useEffect, useState } from "react";
const TEXTS = ["Where Education Meets Fun!", "Education is fun"];
const Banner = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((index) => (index + 1) % TEXTS.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <div
      className=" pt-28 pb-28"
      style={{
        background: "linear-gradient(to right, #3E2352,  #190E21, #000000)",
      }}
    >
      <div className="flex flex-col gap-20 md:flex-row items-center justify-center px-2">
        <div className="md:-ml-[60px] lg:-ml-[100px] xl:-ml-[100px] 2xl:-ml-[400px] w-4/6">
          <img
            className="lg:w-[1050px] md:w-[750px]"
            src="/img/Quest-1-res.png"
            alt=""
          />
        </div>
        <div className="text-white md:ml-20 ml-0 md:px-0 px-3 w-2/6">
          <p className="uppercase text-lg font-semibold mb-5">
            PlayGames2Learn Academy
          </p>
          <h1 className="md:text-7xl lg:text-6xl font-semibold mb-5">
            <h1 className="text-4xl lg:text-5xl font-bold grid lg:mb-12">
                  {" "}
                  <TextTransition
                    className="text-blue-500"
                    springConfig={presets.wobbly}
                  >
                    {TEXTS[index]}
                  </TextTransition>
              </h1>
          </h1>
          <p className="text-lg md:text-xl font-semibold mb-5">
            Unlock the power of education through the magic of gaming. <br />
          </p>
          <button
          onClick={() => document.getElementById("my_modal_2").showModal()}
          className="border-2 rounded-full px-14 py-3 border-[#6630FF] hover:bg-[#6630FF]   hover:transform duration-500 text-lg font-semibold">
            Explore
          </button>
        </div>
      </div>

      <div className="mt-12 max-w-screen-lg mx-auto">
        <div className="flex justify-center">
          <button
            onClick={() => document.getElementById("my_modal_1").showModal()}
            className="relative"
          >
            <img
              src="https://demo.themovation.com/stratus/wp-content/uploads/2020/12/Quest-2.jpg"
              alt="Play Video"
            />
            <MdOutlinePlayCircleOutline className="absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-7xl cursor-pointer" />
          </button>
        </div>
        <Modal></Modal>

        <div className="flex flex-col md:flex-row gap-10 justify-around items-center mt-14">
          <div className="flex items-center justify-center flex-col">
            <div className="w-24 bg-white h-24 flex items-center justify-center rounded-full mb-2">
              <span class="relative flex">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#38204A] opacity-75 h-3 w-3"></span>
                <AiOutlineWifi className="transform rotate-45 text-5xl text-white relative inline-flex rounded-full" />
              </span>
            </div>
            <h2 className="text-white font-semibold">Wireless freedom</h2>
          </div>
          <div className="flex items-center justify-center flex-col">
            <div className="w-24 bg-white h-24 flex items-center justify-center rounded-full mb-2">
              <span class="relative flex">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#38204A] opacity-75 h-3 w-3"></span>
                <PiEye className="transform text-5xl text-white relative inline-flex rounded-full" />
              </span>
            </div>
            <h2 className="text-white font-semibold">Stunning visuals</h2>
          </div>
          <div className="flex items-center justify-center flex-col">
            <div className="w-24 bg-white h-24 flex items-center justify-center rounded-full mb-2">
              <span class="relative flex">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#38204A] opacity-75 h-3 w-3"></span>
                <PiHeadphonesLight className="transform text-5xl text-white relative inline-flex rounded-full" />
              </span>
            </div>
            <h2 className="text-white font-semibold">Fully immersive</h2>
          </div>
          <div className="flex items-center justify-center flex-col">
            <div className="w-24 bg-white h-24 flex items-center justify-center rounded-full mb-2">
              <span class="relative flex">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#38204A] opacity-75 h-3 w-3"></span>
                <CgPerformance className="transform text-5xl text-white relative inline-flex rounded-full" />
              </span>
            </div>
            <h2 className="text-white font-semibold">High performance</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
