import { CgPerformance } from "react-icons/cg";
import { PiBatteryCharging, PiEye, PiHeadphonesLight, PiMonitorLight } from "react-icons/pi";
import { BsPeople } from "react-icons/bs";


const Adds = () => {
    return (
        <div className="bg-white py-10">
            <div className="grid grid-cols-1 gap-20 md:grid-cols-3 lg:grid-cols-3 max-w-screen-xl mx-auto my-28 px-5">
                <div>
                    <PiEye className=' text-5xl ' />
                    <h2 className="font-semibold text-xl my-4">Improved Optics</h2>
                    <p className="text-gray-500">High resulation techture, Realistic particle effects, High-Quality Rendering Techniques.</p>
                </div>
                <div>
                    <CgPerformance className=' text-5xl ' />
                    <h2 className="font-semibold text-xl my-4">High Performance</h2>
                    <p className="text-gray-500">Optimized game design, Performance profiling and testing, Compatibility and Device Support.</p>
                </div>
                <div>
                    <PiBatteryCharging className=' text-5xl ' />
                    <h2 className="font-semibold text-xl my-4">Long Battery Life</h2>
                    <p className="text-gray-500">Monitor battery health, Enable power-saving features, Monitor temperature.</p>
                </div>
                <div>
                    <BsPeople className=' text-5xl ' />
                    <h2 className="font-semibold text-xl my-4">Online Multiplayer</h2>
                    <p className="text-gray-500">Diverse game genres, Cross-platform play, Real-time multiplayer.</p>
                </div>
                <div>
                    <PiMonitorLight className=' text-5xl ' />
                    <h2 className="font-semibold text-xl my-4">Computer Link Option</h2>
                    <p className="text-gray-500">Cloud gaming services, Game progress and syncing, Multiplayer or co-op modes.</p>
                </div>
                <div>
                    <PiHeadphonesLight className=' text-5xl ' />
                    <h2 className="font-semibold text-xl my-4">Immersive 3D Audio</h2>
                    <p className="text-gray-500">Binaural audio, Dynamic sound positioning, lectus lacus fermentum nisi.</p>
                </div>
            </div>
        </div>
    );
};

export default Adds;