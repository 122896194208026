import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import "./designtaion.css";

import { useGetDesignationsQuery } from "../../redux/rtk/features/designation/designationApi";
import ViewBtn from "../Buttons/ViewBtn";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import TablePagination from "./../CommonUi/TablePagination";

const GetAllDesignation = (props) => {
  const { data: list, isLoading: loading } = useGetDesignationsQuery();

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => (
        <Link to={`/admin/designation/${id}`}>{name}</Link>
      ),
    },

    {
      id: 3,
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <UserPrivateComponent permission={"readSingle-designation"}>
          <ViewBtn path={`/admin/designation/${id}`} />
        </UserPrivateComponent>
      ),
    },
  ];
  return (
    <div className='card column-design'>
      <div className='text-xl font-bold px-2'> Designation List</div>
      <div className='card-body p-2'>
        <TablePagination
          columns={columns}
          list={list}
          loading={loading}
          csvFileName={"designations"}
          permission={"readAll-designation"}
        />
      </div>
    </div>
  );
};

export default GetAllDesignation;
