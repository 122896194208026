import { useState, useEffect, useRef } from "react";

import { getMessagesOfChatRoom, sendMessage } from "./ChatService";

import Message from "./Message";
import Contact from "./Contact";
import ChatForm from "./ChatForm";

export default function ChatRoom({ currentChat, currentUser, chatRoom }) {
  const [messages, setMessages] = useState([]);
  const [incomingMessage, setIncomingMessage] = useState(null);

  const scrollRef = useRef();

  const fetchData = async () => {
    const res = await getMessagesOfChatRoom(chatRoom.id);
    console.log("getMessagesOfChatRoom", res.messages);
    setMessages(res.messages);
  };

  useEffect(() => {
    // set time refresh
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    fetchData();
    return () => clearInterval(interval);
  }, [currentChat.id]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [messages]);

  useEffect(() => {
    incomingMessage && setMessages((prev) => [...prev, incomingMessage]);
  }, [incomingMessage]);

  const handleFormSubmit = async (message) => {
    console.log("message", message);
    const data = {
      message: message,
      chatRoomId: chatRoom.id,
      from: currentChat.id,
      to: currentUser.id,
    };
    console.log("data", data);
    const res = await sendMessage(data);
    console.log("res", res);
    fetchData();
  };

  return (
    <div className="lg:col-span-2 lg:block">
      <div className="w-full">
        <div className="p-3 bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-700">
          <Contact chatRoom={currentChat} currentUser={currentUser} />
        </div>

        <div className="relative w-full p-6 overflow-y-auto h-[30rem] bg-white border-b border-gray-200  dark:border-gray-700">
          <ul className="space-y-2">
            {messages.map((message, index) => (
              <div key={index} ref={scrollRef}>
                <Message message={message} self={currentUser.id} />
              </div>
            ))}
          </ul>
        </div>

        <ChatForm handleFormSubmit={handleFormSubmit} />
      </div>
    </div>
  );
}
