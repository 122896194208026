import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      const token = localStorage.getItem("access-token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: [
    "Users",
    "User",
    "Transactions",
    "Accounts",
    "PublicHolidays",
    "TaskTimes",
    "TaskStatusAll",
    "TaskStatusById",
    "TaskStatus",
    "TaskPriority",
    "TaskPriorities",
    "TaskDependency",
    "ProjectTeams",
    "ProjectTeam",
    "ProjectTeamsById",
    "ProjectTask",
    "ProjectAll",
    "Projects",
    "Project",
    "Milestones",
    "MilestoneById",
    "Milestone",
    "AssignedTasks",
    "Payrolls",
    "Payroll",
    "PaySlips",
    "PaySlipsByMonth",
    "Payments",
    "LeavePolicies",
    "LeavePolicy",
    "Leaves",
    "LeaveByStatus",
    "Leave",
    "EmploymentStatus",
    "Designations",
    "Designation",
    "DesignationHistories",
    "DesignationHistory",
    "DesignationByEmployee",
    "AwardHistory",
    "Awards",
    "Attendances",
    "AttendanceById",
    "Attendance",
    "AttendanceAll",
    "Announcements",
    "Announcement",
    "Departments",
    "Department",
    "setting",
    "WeeklyHolidays",
    "WeeklyHoliday",
    "Roles",
    "Role",
    "Shifts",
    "Shift",
    "Educations",
    "Education",
    "SalaryHistories",
    "SalaryHistory",
  ],
  endpoints: (builder) => ({}),
});
