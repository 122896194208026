import { Card, Table } from "antd";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import PageTitle from "../../page-header/PageHeader";
import AddProjectTeam from "./AddProjectTeam";

import {
  projectTeamApi,
  useGetProjectTeamsQuery,
} from "../../../redux/rtk/features/projectManagement/project/projectTeam/projectTeamApi";
import ViewBtn from "../../Buttons/ViewBtn";
import ProjectTeamStatusUpdatePopup from "../../UI/PopUp/ProjectManagemnet/ProjectTeamStatusUpdatePopup";
import CommonDelete from "./../../CommonUi/CommonDelete";

const ProjectTeam = () => {
  const { data: list, isLoading } = useGetProjectTeamsQuery({ status: true });

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status ? "True" : "False"),
    },
    {
      id: 3,
      title: "Team Name",
      dataIndex: "projectTeamName",
      key: "projectTeamName",
    },
    {
      id: 4,
      title: "Action",
      key: "action",
      render: ({ id, projectTeamName, status }) => (
        <div className='flex justify-start'>
          <ViewBtn path={`/admin/team/${id}`} />
          <ProjectTeamStatusUpdatePopup
            projectId={id}
            teamName={projectTeamName}
            status={status}
          />
          <CommonDelete
            permission={"delete-projectTeam"}
            deleteThunk={projectTeamApi.endpoints.deleteProjectTeam.initiate}
            id={id}
          />
        </div>
      ),
    },
  ];

  const isLogged = Boolean(localStorage.getItem("isLogged"));
  const [columnsToShow, setColumnsToShow] = useState([]);

  useEffect(() => {
    setColumnsToShow(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }
  return (
    <div>
      <PageTitle title='Back' />
      <AddProjectTeam />
      <Card>
        <h1 className='text-xl mb-4'> Team List </h1>
        <Table
          scroll={{ x: true }}
          loading={isLoading}
          pagination={{
            defaultPageSize: 20,
          }}
          columns={columnsToShow}
          dataSource={list}
        />
      </Card>
    </div>
  );
};

export default ProjectTeam;
