import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import "./account.css";

import { Card, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import ViewBtn from "../Buttons/ViewBtn";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import CommonDelete from "../CommonUi/CommonDelete";
import { accountApi, useGetAccountsQuery } from "../../redux/rtk/features/account/accountApi";

//Date fucntinalities
let startdate = dayjs(new Date()).format("YYYY-MM-DD");
let enddate = dayjs(new Date()).add(1, "day").format("YYYY-MM-DD");

function CustomTable() {
	const { data: list, isLoading } = useGetAccountsQuery();
	const [columnsToShow, setColumnsToShow] = useState([]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const columns = [
		{
			id: 1,
			title: "ID",
			dataIndex: "id",
			key: "id",
			render: (id) => <Link to={`/admin/account/${id}`}>{id}</Link>,
		},

		{
			id: 2,
			title: "Account",
			dataIndex: "name",
			key: "name",
		},

		{
			id: 3,
			title: "Account Type ",
			dataIndex: "account",
			key: "account",
			render: (account) => account?.name,
			responsive: ["md"],
		},
		{
			id: 4,
			title: "Action",
			key: "action",
			render: ({ id }) => (
				<div className='flex justify-start align-middle'>
					<UserPrivateComponent permission={"readSingle-account"}>
						<ViewBtn path={`/admin/account/${id}`} />
					</UserPrivateComponent>
					<CommonDelete
						permission={"delete-account"}
						deleteThunk={accountApi.endpoints.deleteAccount.initiate}
						id={id}
					/>
				</div>
			),
		},
	];

	useEffect(() => {
		// setColumnItems(menuItems);
		setColumnsToShow(columns);
	}, [columns]);

	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

	const CSVlist = list?.map((i) => ({
		...i,
		account: i?.account?.name,
	}));

	return (
		<div>
			<div className='flex justify-end mr-5 '>
				{list && (
					<div>
						<CsvLinkBtn>
							<CSVLink data={CSVlist} filename='accounts'>
								Download CSV
							</CSVLink>
						</CsvLinkBtn>
					</div>
				)}
			</div>

			{list && (
				<div style={{ marginBottom: "30px" }} className=' ml-5  mt-4'>
					<ColVisibilityDropdown
						options={columns}
						columns={columns}
						columnsToShowHandler={columnsToShowHandler}
					/>
				</div>
			)}

			<Table
				scroll={{ x: true }}
				loading={isLoading}
				pagination={{
					defaultPageSize: 20,
					pageSizeOptions: [10, 20, 50, 100, 200],
					showSizeChanger: true,

					onChange: (page, limit) => {

					},
				}}
				columns={columnsToShow}
				dataSource={list ? addKeys(list) : []}
			/>
		</div>
	);
}

const GetAllAccount = (props) => {

	return (
		<Card className=''>
			<h5 className=' text-2xl'>
				<span className='ml-4'>Accounts List</span>
			</h5>

			<CustomTable startdate={startdate} enddate={enddate} />
		</Card>
	);
};

export default GetAllAccount;
