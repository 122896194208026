export default function UserLayout({ user, currentUser }) {
    return (
      <>
      {
        user?.id === currentUser.id ?
        (
          <></>
        ) : (
          <div className="relative flex items-center">
            <img className="w-10 h-10 rounded-full" src={`https://ui-avatars.com/api/?name=${user?.firstName}&length=2`} alt="" />
            <span className="block ml-2 text-gray-500 dark:text-gray-400">
               {user?.firstName + " " + user?.lastName}
            </span>
          </div>)
      }
      </>
    );
  }