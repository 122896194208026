import { Button, Col, DatePicker, Form, Input, Row, Typography } from "antd";

import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import {
  useAddPublicHolidayMutation,
  useGetPublicHolidaysQuery,
} from "../../redux/rtk/features/publicHoliday/publicHolidayApi";
import ViewBtn from "../Buttons/ViewBtn";
import TablePagination from "../CommonUi/TablePagination";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

const AddPublicHoliday = ({ drawer }) => {
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    count: 10,
    status: true,
  });
  const { data: list, isLoading } = useGetPublicHolidaysQuery(pageConfig);
  const [addPublicHoliday, { isLoading: addLoading }] =
    useAddPublicHolidayMutation();
  const [form] = Form.useForm();

  const { Title } = Typography;

  const onFinish = async (values) => {
    const resp = await addPublicHoliday(values);
    resp && form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
  };
  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("DD/MM/YYYY"),
    },

    {
      id: 3,
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("DD/MM/YYYY"),
    },
    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => (
        <UserPrivateComponent permission={"readSingle-publicHoliday"}>
          <ViewBtn path={`/admin/holiday/public/${id}/`} />
        </UserPrivateComponent>
      ),
    },
  ];
  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"create-publicHoliday"}>
        <Row className='mr-top' justify={drawer ? "center" : "space-between"}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={drawer ? 22 : 12}
            xl={drawer ? 22 : 12}
            className='column-design border rounded card-custom'
          >
            <Title level={4} className='m-2 mt-5 mb-5 text-center'>
              Add Public Holiday
            </Title>
            <Form
              style={{ marginBottom: "40px" }}
              form={form}
              eventKey='department-form'
              name='basic'
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 12,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <div>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: "Please input name!",
                    },
                  ]}
                >
                  <Input placeholder='New Year' />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "20px" }}
                  label='Date'
                  name='date'
                  rules={[
                    {
                      required: true,
                      message: "Please input date!",
                    },
                  ]}
                >
                  <DatePicker placeholder='Select Date' />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  wrapperCol={{
                    offset: 6,
                    span: 12,
                  }}
                >
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    block
                    loading={addLoading}
                  >
                    Add Public Holiday
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </UserPrivateComponent>
      <hr />
      <h5 className='department-list-title text-color-2 text-xl my-3'>
        Public Holiday List
      </h5>
      {drawer || (
        <TablePagination
          columns={columns}
          list={list}
          setPageConfig={setPageConfig}
          loading={isLoading}
          csvFileName={"Public holyday list"}
          permission={"readAll-publicHoliday"}
        />
      )}
    </Fragment>
  );
};

export default AddPublicHoliday;
