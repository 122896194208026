import { Card, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Navigate, useParams } from "react-router-dom";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import BtnLoader from "../loader/BtnLoader";
import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";

import {
  useGetWeeklyHolidayQuery,
  weeklyHolidayApi,
} from "../../redux/rtk/features/weeklyHoliday/weeklyHolidayApi";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import WeeklyHolidayEdit from "../UI/PopUp/WeeklyHolidayEditPopup";
import CommonDelete from "./../CommonUi/CommonDelete";

//PopUp

const CustomTable = ({ list, data }) => {
  const [columnsToShow, setColumnsToShow] = useState([]);

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      id: 2,
      title: " Name",
      key: "name",

      render: ({ firstName, lastName }) => firstName + " " + lastName,
    },

    {
      id: 6,
      title: "Start Day",
      key: "startDay",
      render: () => data?.startDay,
    },

    {
      id: 6,
      title: "End Day",
      key: "endDay",
      render: () => data?.endDay,
    },

    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => (
        <UserPrivateComponent permission={"readSingle-user"}>
          <ViewBtn path={`/admin/hr/staffs/${id}/`} />
        </UserPrivateComponent>
      ),
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  return (
    <div>
      <div className='text-center my-2 flex justify-between'>
        <h5 className='award-list-title text-color-2 text-xl mb-2'>
          Employee List
        </h5>

        {list && (
          <div>
            <CsvLinkBtn>
              <CSVLink data={list} filename='user_award'>
                Download CSV
              </CSVLink>
            </CsvLinkBtn>
          </div>
        )}
      </div>
      {list && (
        <div style={{ marginBottom: "30px" }}>
          <ColVisibilityDropdown
            options={columns}
            columns={columns}
            columnsToShowHandler={columnsToShowHandler}
          />
        </div>
      )}
      <Table
        loading={!list}
        columns={columnsToShow}
        dataSource={addKeys(list)}
        pagination={{ pageSize: 5 }}
        scroll={{ x: 720 }}
      />
    </div>
  );
};

const DetailWeeklyHoliday = () => {
  const { id } = useParams();

  const { data: weeklyHoliday, isLoading: loading } =
    useGetWeeklyHolidayQuery(id);

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <div>
      <PageTitle title=' Back  ' />

      <UserPrivateComponent permission={"readSingle-weeklyHoliday"}>
        <Card className='mr-top mt-5'>
          {weeklyHoliday ? (
            <Fragment key={weeklyHoliday.id}>
              <div>
                <div className='flex justify-between '>
                  <h3 className={"text-xl"}>
                    ID : {weeklyHoliday.id} | {weeklyHoliday.name}
                  </h3>
                  <div className='flex justify-end '>
                    <UserPrivateComponent permission={"update-weeklyHoliday"}>
                      <WeeklyHolidayEdit data={weeklyHoliday} />
                    </UserPrivateComponent>
                    {!loading ? (
                      <CommonDelete
                        id={id}
                        permission={"delete-weeklyHoliday"}
                        deleteThunk={
                          weeklyHolidayApi.endpoints.deleteWeeklyHoliday
                            .initiate
                        }
                      />
                    ) : (
                      <BtnLoader />
                    )}
                  </div>
                </div>
                <CustomTable list={weeklyHoliday.user} data={weeklyHoliday} />
              </div>
            </Fragment>
          ) : (
            <Loader />
          )}
        </Card>
      </UserPrivateComponent>
    </div>
  );
};

export default DetailWeeklyHoliday;
