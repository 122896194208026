import { Card, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Navigate, useParams } from "react-router-dom";
import ViewBtn from "../Buttons/ViewBtn";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { CsvLinkBtn } from "../UI/CsvLinkBtn";
import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";

import {
  leavePolicyApi,
  useGetLeavePolicyQuery,
} from "../../redux/rtk/features/leavePolicy/leavePolicyApi";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import LeavePolicyEdit from "../UI/PopUp/LeavePolicyEditPopup";
import CommonDelete from "./../CommonUi/CommonDelete";

//PopUp

const CustomTable = ({ list, data }) => {
  const [columnsToShow, setColumnsToShow] = useState([]);

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      id: 2,
      title: " Name",
      key: "name",

      render: ({ firstName, lastName }) => firstName + " " + lastName,
    },

    {
      id: 6,
      title: "Paid Leave",
      key: "paidLeaveCount",
      render: () => data?.paidLeaveCount,
    },

    {
      id: 6,
      title: "Unpaid Leave",
      key: "unpaidLeaveCount",
      render: () => data?.unpaidLeaveCount,
    },

    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => (
        <UserPrivateComponent permission={"readSingle-user"}>
          <ViewBtn path={`/admin/hr/staffs/${id}/`} />
        </UserPrivateComponent>
      ),
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  return (
    <div>
      <div className='text-center my-2 flex justify-between'>
        <h5 className='award-list-title text-color-2 text-xl mb-2'>
          Employee List
        </h5>

        {list && (
          <div>
            <CsvLinkBtn>
              <CSVLink data={list} filename='user_award'>
                Download CSV
              </CSVLink>
            </CsvLinkBtn>
          </div>
        )}
      </div>
      {list && (
        <div style={{ marginBottom: "30px" }}>
          <ColVisibilityDropdown
            options={columns}
            columns={columns}
            columnsToShowHandler={columnsToShowHandler}
          />
        </div>
      )}
      <Table
        loading={!list}
        columns={columnsToShow}
        dataSource={addKeys(list)}
        pagination={{ pageSize: 5 }}
        scroll={{ x: 720 }}
      />
    </div>
  );
};

const DetailLeavePolicy = () => {
  const { id } = useParams();
  const { data: leavePolicy } = useGetLeavePolicyQuery(id);

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <div>
      <PageTitle title=' Back  ' />

      <UserPrivateComponent permission={"readSingle-leavePolicy"}>
        <Card className='mr-top mt-5'>
          {leavePolicy ? (
            <Fragment key={leavePolicy.id}>
              <div>
                <div className='flex justify-between '>
                  <h3 className={"text-xl"}>
                    ID : {leavePolicy.id} | {leavePolicy.name}
                  </h3>
                  <div className='flex justify-end'>
                    <UserPrivateComponent permission={"update-leavePolicy"}>
                      <LeavePolicyEdit data={leavePolicy} />
                    </UserPrivateComponent>

                    <CommonDelete
                      permission={"delete-leavePolicy"}
                      id={id}
                      deleteThunk={
                        leavePolicyApi.endpoints.deleteLeavePolicy.initiate
                      }
                    />
                  </div>
                </div>
                <CustomTable list={leavePolicy.user} data={leavePolicy} />
              </div>
            </Fragment>
          ) : (
            <Loader />
          )}
        </Card>
      </UserPrivateComponent>
    </div>
  );
};

export default DetailLeavePolicy;
