import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const transactionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: ({ page, count, startdate, enddate, status = true }) => ({
        url: `transaction?status=${status}&page=${page}&count=${count}&startdate=${startdate}&enddate=${enddate}`,
      }),
      providesTags: ["Transactions"],
    }),

    getTransaction: builder.query({
      query: (id) => ({
        url: `transaction/${id}`,
      }),
      providesTags: ["Transaction"],
    }),

    addTransaction: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `transaction/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Transaction added successfully");
        } catch (err) {
          toast.error("An error occurred while adding transaction");
        }
      },
      invalidatesTags: ["Transactions"],
    }),

    updateTransaction: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `transaction/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Transaction updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating transaction");
        }
      },
      invalidatesTags: ["Transactions"],
    }),

    deleteTransaction: builder.mutation({
      query: (id) => ({
        url: `transaction/${id}`,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted transaction successful");
        } catch (err) {
          toast.error("An error occurred while deleting the transaction");
        }
      },
      invalidatesTags: ["Transactions", "Transaction"],
    }),
  }),
});

export const {
  useGetTransactionQuery,
  useGetTransactionsQuery,
  useAddTransactionMutation,
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
} = transactionApi;
