import { configureStore } from "@reduxjs/toolkit";

import accountSlice from "../features/account/accountSlice";
import announcementSlice from "../features/announcement/announcementSlice";
import { apiSlice } from "../features/api/apiSlice";
import attendanceReducer from "../features/attendance/attendanceSlice";
import awardSlice from "../features/award/awardSlice";
import awardHistorySlice from "../features/awardHistory/awardHistorySlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import designationReducer from "../features/designation/designationSlice";
import employmentStatusSlice from "../features/employemntStatus/employmentStatusSlice";
import leaveSlice from "../features/leave/leaveSlice";
import leavePolicySlice from "../features/leavePolicy/leavePolicySlice";
import paymentSlice from "../features/payment/paymentSlice";
import payrollSlice from "../features/payroll/payrollSlice";
import milestoneSlice from "../features/projectManagement/project/milestone/milestone";
import projectSlice from "../features/projectManagement/project/project/project";
import projectTaskSlice from "../features/projectManagement/project/projectTask/projectTask";
import projectTeamSlice from "../features/projectManagement/project/projectTeam/projectTeam";
import taskDependencySlice from "../features/projectManagement/project/taskDependency/taskDependency";
import taskPrioritySlice from "../features/projectManagement/project/taskPriority/taskPriority";
import taskStatusSlice from "../features/projectManagement/project/taskStatus/taskStatus";
import taskTimeSlice from "../features/projectManagement/project/taskTime/taskTime";
import publicHolidaySlice from "../features/publicHoliday/publicHolidaySlice";
import shiftSlice from "../features/shift/shiftSlice";
import transactionSlice from "../features/transaction/transactionSlice";
import userReducer from "../features/user/userSlice";
import weeklyHolidaySlice from "../features/weeklyHoliday/weeklyHolidaySlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    users: userReducer,
    dashboard: dashboardReducer,
    designations: designationReducer,
    payroll: payrollSlice,
    payment: paymentSlice,
    shift: shiftSlice,
    employmentStatus: employmentStatusSlice,
    attendance: attendanceReducer,
    leave: leaveSlice,
    accounts: accountSlice,
    transactions: transactionSlice,
    announcement: announcementSlice,
    award: awardSlice,
    awardHistory: awardHistorySlice,
    leavePolicy: leavePolicySlice,
    weeklyHoliday: weeklyHolidaySlice,
    publicHoliday: publicHolidaySlice,
    milestone: milestoneSlice,
    project: projectSlice,
    projectTask: projectTaskSlice,
    projectTeam: projectTeamSlice,
    taskDependency: taskDependencySlice,
    taskStatus: taskStatusSlice,
    taskTime: taskTimeSlice,
    taskPriority: taskPrioritySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export default store;
