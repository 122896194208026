import { Card, List } from "antd";
import {
  announcementApi,
  useGetAnnouncementsQuery,
} from "../../redux/rtk/features/announcement/announcementApi";
import CommonDelete from "./../CommonUi/CommonDelete";

const TitleComponent = ({ item }) => {
  return (
    <div className='flex justify-between'>
      <h2 className='text-xl txt-color-2'>{item.title}</h2>
      <div className='flex justify-end'>
        <CommonDelete
          permission={"delete-announcement"}
          deleteThunk={announcementApi.endpoints.deleteAnnouncement.initiate}
          id={item.id}
        />
      </div>
    </div>
  );
};

const GetAllAnnouncement = () => {
  const { isLoading, data: list } = useGetAnnouncementsQuery();

  return (
    <Card>
      <h2 className='text-center text-2xl txt-color-2'>Announcements</h2>
      <hr className='mt-3 mb-5 mx-5' />
      <List
        className='m-4'
        loading={isLoading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={list ? list : []}
        renderItem={(item) => (
          <List.Item className='new-card'>
            <Card title={<TitleComponent item={item} />}>
              {item.description}
            </Card>
          </List.Item>
        )}
      />
    </Card>
  );
};
export default GetAllAnnouncement;
