import React, { useState, useRef } from "react";
import { HiSearchCircle } from "react-icons/hi";
import { IoSendSharp } from "react-icons/io5";

export default function ChatForm(props) {
  const [message, setMessage] = useState("");


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    props.handleFormSubmit(message);
    setMessage("");
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <div className="flex items-center justify-between w-full p-3 bg-white border-b border-gray-200 dark:border-gray-700">
          <input
            type="text"
            placeholder="Write a message"
            className="block w-full py-2 pl-4 mx-3 outline-none bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400dark:focus:ring-blue-500 dark:focus:border-blue-500"
            name="message"
            required
            value={message} 
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type="submit">
            <IoSendSharp
              className="h-6 w-6 text-blue-600 dark:text-blue-500"
              aria-hidden="true"
            />
          </button>
        </div>
      </form>
    </div>
  );
}