import { useEffect, useState } from "react";

import { createChatRoom, getChatRooms } from "./ChatService.js";

import ChatRoom from "./ChatRoom.js";
import Welcome from "./Welcome.js";
import AllUsers from "./AllUsers";
import jwtDecode from "jwt-decode";

export default function ChatLayout() {
  // const [users, SetUsers] = useState([]);
  // const [filteredUsers, setFilteredUsers] = useState([]);
  const [chatRooms, setChatRooms] = useState([]);
  const [chatRoom, setChatRoom] = useState([]);
  // const [filteredRooms, setFilteredRooms] = useState([]);

  const [currentChat, setCurrentChat] = useState();
  const [token, setToken] = useState("");
  const [currentUser, setCurrentUser] = useState({
    id : 1,
  });
  // const [onlineUsersId, setonlineUsersId] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");

  // const [isContact, setIsContact] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getChatRooms(currentUser.id);
      console.log("res", res);
      setChatRooms(res);
    };

    fetchData();
  }, [currentUser.id]);

  useEffect(() => {
    const tokenSaved = localStorage.getItem("access-token");
    const decoded = jwtDecode(tokenSaved);
    const i = decoded.sub;
    setCurrentUser({ id: i });
    setToken(token);
  }, []);

  const handleChatChange = async (chat) => {
    const data = {
      to: chat.id,
      from: currentUser.id,
    };
    const res = await createChatRoom(data);
    setChatRoom(res.room);
    setCurrentChat(chat);
  };

  return (
    <div className="container mx-auto">
      <div className="min-w-full bg-white border-x border-b border-gray-200 rounded lg:grid lg:grid-cols-3">
        <div className="bg-white border-r border-gray-200 lg:col-span-1">
          <AllUsers
            chatRooms={chatRooms}
            currentUser={currentUser}
            changeChat={handleChatChange}
          />
        </div>

        {currentChat ? (
          <ChatRoom currentChat={currentChat} currentUser={currentUser} chatRoom={chatRoom} />
        ) : (
          <Welcome />
        )}
      </div>
    </div>
  );
}
