import { toast } from "react-toastify";
import { apiSlice } from "../../../api/apiSlice";

export const projectTeamApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectTeams: builder.query({
      query: ({ status }) => ({
        url: `project-team?status=${status}`,
      }),
      providesTags: ["ProjectTeams"],
    }),

    getProjectTeamByProjectId: builder.query({
      query: (id) => ({
        url: `project-team/${id}/project`,
      }),
      providesTags: ["ProjectTeamsById"],
    }),

    getProjectTeam: builder.query({
      query: (id) => ({
        url: `project-team/${id}`,
      }),
      providesTags: ["ProjectTeam"],
    }),

    addProjectTeam: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `project-team/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("ProjectTeam added successfully");
        } catch (err) {
          toast.error("An error occurred while adding ProjectTeam");
        }
      },
      invalidatesTags: ["ProjectTeams"],
    }),

    updateProjectTeam: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `project-team/${id}?query=all`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("ProjectTeam updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating ProjectTeam");
        }
      },
      invalidatesTags: ["ProjectAll", "ProjectTeams", "ProjectTeam"],
    }),

    updateProjectTeamStatus: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `project-team/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("ProjectTeam Status updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating ProjectTeam Status");
        }
      },
      invalidatesTags: ["ProjectAll", "ProjectTeams", "ProjectTeam"],
    }),

    deleteProjectTeam: builder.mutation({
      query: (id) => ({
        url: `project-team/${id}`,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted ProjectTeam successful");
        } catch (err) {
          toast.error("An error occurred while deleting the ProjectTeam");
        }
      },
      invalidatesTags: ["ProjectTeams"],
    }),
  }),
});

export const {
  useGetProjectTeamsQuery,
  useGetProjectTeamByProjectIdQuery,
  useGetProjectTeamQuery,
  useAddProjectTeamMutation,
  useUpdateProjectTeamMutation,
  useUpdateProjectTeamStatusMutation,
  useDeleteProjectTeamMutation,
} = projectTeamApi;
