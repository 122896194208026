import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const employmentStatusApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmploymentStatuses: builder.query({
      query: () => ({
        url: `employment-status?status=true`,
      }),
      providesTags: ["EmploymentStatuses"],
    }),

    getEmploymentStatus: builder.query({
      query: (id) => ({
        url: `employment-status/${id}`,
      }),
      providesTags: ["EmploymentStatus"],
    }),

    addEmploymentStatus: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `employment-status/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;

          toast.success("EmploymentStatus added successfully");
        } catch (err) {
          toast.error("An error occurred while adding EmploymentStatus");
        }
      },
      invalidatesTags: ["EmploymentStatuses"],
    }),

    updateEmploymentStatus: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `employment-status/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("EmploymentStatus updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating EmploymentStatus");
        }
      },
      invalidatesTags: ["EmploymentStatuses", "EmploymentStatus"],
    }),

    deleteEmploymentStatus: builder.mutation({
      query: (id) => ({
        url: `employment-status/${id}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted EmploymentStatus successful");
        } catch (err) {
          toast.error(
            "An error occurred while deleting the EmploymentStatus"
          );
        }
      },
      invalidatesTags: ["EmploymentStatuses", "EmploymentStatus"],
    }),
  }),
});

export const {
  useGetEmploymentStatusesQuery,
  useGetEmploymentStatusQuery,
  useAddEmploymentStatusMutation,
  useUpdateEmploymentStatusMutation,
  useDeleteEmploymentStatusMutation,
} = employmentStatusApi;
