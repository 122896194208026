import { toast } from "react-toastify";
import { apiSlice } from "./../../../api/apiSlice";

export const projectTaskApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectTasks: builder.query({
      query: () => ({
        url: `tasks?status=true`,
      }),
      providesTags: ["ProjectTasks"],
    }),

    getProjectTask: builder.query({
      query: (id) => ({
        url: `tasks/${id}`,
      }),
      providesTags: ["ProjectTask"],
    }),

    addProjectTask: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `tasks/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("ProjectTask added successfully");
        } catch (err) {
          toast.error("An error occurred while adding ProjectTask");
        }
      },
      invalidatesTags: ["ProjectTasks"],
    }),

    updateProjectTask: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `tasks/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("ProjectTask updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating ProjectTask");
        }
      },
      invalidatesTags: ["ProjectTasks", "ProjectTask"],
    }),

    updateProjectTaskStatus: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `tasks/${id}?query=taskStatus`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("ProjectTask Status updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating ProjectTask Status");
        }
      },
      invalidatesTags: ["ProjectTasks", "ProjectTask"],
    }),

    deleteProjectTask: builder.mutation({
      query: (id) => ({
        url: `tasks/${id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        // pessimistic user cache update
        try {
          await queryFulfilled;
          toast.success("Deleted ProjectTask successful");
        } catch (err) {
          toast.error("An error occurred while deleting the ProjectTask");
        }
      },
      invalidatesTags: ["ProjectTasks", "ProjectTask"],
    }),
  }),
});

export const {
  useGetProjectTasksQuery,
  useGetProjectTaskQuery,
  useAddProjectTaskMutation,
  useUpdateProjectTaskMutation,
  useUpdateProjectTaskStatusMutation,
  useDeleteProjectTaskMutation,
} = projectTaskApi;
