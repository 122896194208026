import { PiShoppingCartThin } from "react-icons/pi";
import { MdOutlineBookmarkAdd } from "react-icons/md";
const OculusQuest = () => {
  return (
    <div className="bg-[#F2F2F2]">
      <div className="flex flex-col md:flex-row max-w-screen-xl mx-auto items-center py-8">
        <div>
          <img className="w-[695px]" src="/img/bb-w.jpg" alt="" />
        </div>
        <div className="p-5">
          <h1 className="text-4xl font-semibold mb-5">Be Billionaire</h1>
          <p className="text-gray-500 text-lg font-semibold text-justify">
            Forget the classroom, become a mogul! Buy, <br />
            sell, and develop properties, <br />
            applying accounting principles in real-time.
          </p>
          <div className="flex items-center gap-5 my-5">
            <p className="text-xl font-semibold animate-bounce">FREE</p>
            <button className="bg-[#6630FF] py-4 px-14 rounded-full font-semibold text-white"
            onClick={()=>{
              // download the app
              window.location.href = "/apk/Be Billionaire Game.apk";
            }}
            >
              Download Now
            </button>
            <button
              onClick={()=>{
                window.location.replace("https://www.youtube.com/watch?v=L_zRhzrgwgE");
              }}
            className="border-2 rounded-full px-14 py-3 border-[#6630FF] hover:bg-[#6630FF]   hover:transform duration-500 text-lg font-semibold">
              Explore
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OculusQuest;
