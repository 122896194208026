import { Button, Col, Form, Input, Row, TimePicker, Typography } from "antd";

import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import {
  useAddShiftMutation,
  useGetShiftsQuery,
} from "../../redux/rtk/features/shift/shiftApi";
import ViewBtn from "../Buttons/ViewBtn";
import TablePagination from "../CommonUi/TablePagination";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";

const AddShift = ({ drawer }) => {
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    count: 10,
    status: true,
  });
  const { data: shift, isLoading } = useGetShiftsQuery(pageConfig);
  const [addShift, { isLoading: addLoading }] = useAddShiftMutation();

  const { Title } = Typography;
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const shiftData = {
      name: values.name,
      startTime: dayjs(values.startTime).format(),
      endTime: dayjs(values.endTime).format(),
    };
    addShift(shiftData);
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding shift");
  };
  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => dayjs(startTime).format("hh:mm A"),
    },

    {
      id: 4,
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime) => dayjs(endTime).format("hh:mm A"),
    },
    {
      id: 5,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => <ViewBtn path={`/admin/shift/${id}/`} />,
    },
  ];
  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"create-shift"}>
        <Row className='mr-top' justify={drawer ? "center" : "space-between"}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={drawer ? 22 : 16}
            xl={drawer ? 22 : 12}
            className='column-design border rounded card-custom'
          >
            <Title level={4} className='m-2 mt-5 mb-5 text-center'>
              Add shift
            </Title>
            <Form
              form={form}
              style={{ marginBottom: "40px" }}
              eventKey='shift-form'
              name='basic'
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 12,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <div>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: "Please input your shift!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Start Time'
                  name='startTime'
                  rules={[
                    {
                      required: true,
                      message: "Please input your shift!",
                    },
                  ]}
                >
                  <TimePicker />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "20px" }}
                  label='End Time'
                  name='endTime'
                  rules={[
                    {
                      required: true,
                      message: "Please input your shift!",
                    },
                  ]}
                >
                  <TimePicker />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  wrapperCol={{
                    offset: 6,
                    span: 12,
                  }}
                >
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    block
                    loading={addLoading}
                  >
                    Add New Shift
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </UserPrivateComponent>
      <hr />

      <TablePagination
        list={shift}
        columns={columns}
        csvFileName={"shift list"}
        loading={isLoading}
        setPageConfig={setPageConfig}
        permission={"readAll-shift"}
		
      />
    </Fragment>
  );
};

export default AddShift;
