import { EditOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import { Fragment } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import Loader from "../loader/loader";
import PageTitle from "../page-header/PageHeader";
import {
  designationApi,
  useGetDesignationQuery,
} from "./../../redux/rtk/features/designation/designationApi";
import CommonDelete from "./../CommonUi/CommonDelete";
import UserListCard from "./List/UserListCard";
//PopUp

const DetailDesignation = () => {
  const { id } = useParams();

  const { data: designation, isLoading: loading } = useGetDesignationQuery(id);

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <div>
      <PageTitle title=' Back ' subtitle=' ' />

      <div className='mr-top'>
        <UserPrivateComponent permission={"readSingle-designation"}>
          {designation ? (
            <Fragment key={designation.id}>
              <Card bordered={false} style={{}}>
                <div className='flex justify-between' style={{ padding: 0 }}>
                  <div className='w-50'>
                    <h5>
                      <i className='bi bi-person-lines-fill'></i>
                      <span className='mr-left text-xl'>
                        ID : {designation.designationId} | {designation.name}
                      </span>
                    </h5>
                  </div>
                  <div className='text-end w-50'>
                    <UserPrivateComponent permission={"update-designation"}>
                      <Link
                        className='mr-3 d-inline-block'
                        to={`/admin/designation/${designation.designationId}/update`}
                        state={{ data: designation }}
                      >
                        <Button
                          type='primary'
                          shape='round'
                          icon={<EditOutlined />}
                        ></Button>
                      </Link>
                    </UserPrivateComponent>

                    <CommonDelete
                      navigatePath={"/admin/designation"}
                      permission={"delete-designation"}
                      deleteThunk={
                        designationApi.endpoints.deleteDesignation.initiate
                      }
                      id={id}
                    />
                  </div>
                </div>

                <UserListCard list={designation.employee} loading={loading} />
              </Card>
            </Fragment>
          ) : (
            <Loader />
          )}
        </UserPrivateComponent>
      </div>
    </div>
  );
};

export default DetailDesignation;
