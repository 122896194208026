import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";

export const announcementApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query({
      query: () => ({
        url: `announcement?query=all`,
      }),
      providesTags: ["Announcements"],
    }),

    getAnnouncement: builder.query({
      query: (id) => ({
        url: `announcement/${id}`,
      }),
      providesTags: ["Announcement"],
    }),

    addAnnouncement: builder.mutation({
      query: (values) => ({
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `announcement/`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Announcement added successfully");
        } catch (err) {
          toast.error("An error occurred while adding Announcement");
        }
      },
      invalidatesTags: ["Announcements", "Announcement"],
    }),

    updateAnnouncement: builder.mutation({
      query: ({ id, values }) => ({
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `announcement/${id}`,
        body: values,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Announcement updated successfully");
        } catch (err) {
          toast.error("An error occurred while updating Announcement");
        }
      },
      invalidatesTags: ["Announcements", "Announcement"],
    }),

    deleteAnnouncement: builder.mutation({
      query: (id) => ({
        url: `announcement/${id}`,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: {
          status: false,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          toast.success("Deleted Announcement successful");
        } catch (err) {
          toast.error("An error occurred while deleting the Announcement");
        }
      },
      invalidatesTags: ["Announcements", "Announcement"],
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useGetAnnouncementQuery,
  useAddAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
} = announcementApi;
