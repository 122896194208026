import { Button, Col, Form, Input, Row, Typography } from "antd";

import dayjs from "dayjs";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import {
  useAddDepartmentMutation,
  useGetDepartmentsQuery,
} from "../../redux/rtk/features/Department/departmentApi";
import ViewBtn from "../Buttons/ViewBtn";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import TablePagination from "./../CommonUi/TablePagination";

const AddDepartment = ({ drawer }) => {
  const { data: list, isLoading } = useGetDepartmentsQuery();
  const [addDepartment, { isLoading: addLoading }] = useAddDepartmentMutation();

  const { Title } = Typography;

  const onFinish = async (values) => {
    await addDepartment(values);
  };

  const onFinishFailed = (errorInfo) => {
    toast.warning("Failed at adding department");
  };
  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      id: 3,
      title: "Created at",
      dataIndex: "createdAt",
      key: "addrcreatedAtess",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD"),
    },
    {
      id: 4,
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id) => <ViewBtn path={`/admin/department/${id}/`} />,
    },
  ];
  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"create-department"}>
        <Row className='mr-top' justify={drawer ? "center" : "center"}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={drawer ? 22 : 16}
            xl={drawer ? 22 : 12}
            className='column-design border rounded card-custom'
          >
            <Title level={4} className='m-2 mt-5 mb-5 text-center'>
              Add department
            </Title>
            <Form
              style={{ marginBottom: "40px" }}
              eventKey='department-form'
              name='basic'
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 12,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <div>
                <Form.Item
                  style={{ marginBottom: "20px" }}
                  label='Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: "10px" }}
                  wrapperCol={{
                    offset: 6,
                    span: 12,
                  }}
                >
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    block
                    loading={addLoading}
                  >
                    Add New department
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </UserPrivateComponent>
      <hr />
      <h5 className='department-list-title text-color-2 text-xl mb-2'>
        Department List
      </h5>
      {drawer || (
        <TablePagination
          columns={columns}
          list={list}
          permission={"readAll-department"}
          loading={isLoading}
          csvFileName={"departments"}
        />
      )}
    </Fragment>
  );
};

export default AddDepartment;
